import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import Box from '@mui/material/Box';

import { Email, User } from 'api/users/types';
import { ContainerWithTitle } from 'components/elements/container-with-title';
import { TextField } from 'components/form/fields/text-field/TextField';

import { TEST_ID } from './constants';

import { useUpdateUserEmail } from '../../../../../api/users/hooks';
import { isValidEmail } from '../../../../../components/create-agency-dialog/util';
import { InlineAlert } from '../../../../../components/inline-alert';
import { UpdateUserFormWrapper } from '../update-user-form-wrapper';

interface Props {
  user: User;
  onCancel: () => void;
}

export const EditEmail: React.FC<Props> = ({ user, onCancel }) => {
  const intl = useIntl();
  const mutation = useUpdateUserEmail();
  const [email, setEmail] = useState('');
  const hasValidEmail = useMemo(() => !email || isValidEmail(email), [email]);

  return (
    <Box>
      <UpdateUserFormWrapper
        mutation={mutation}
        saveDisabled={!hasValidEmail}
        onSave={() => {
          mutation.mutate(
            {
              user,
              email: email as Email,
            },
            {
              onSuccess: () => {
                onCancel();
              },
            }
          );
        }}
        onCancel={onCancel}
      >
        <Box mt={3}>
          <ContainerWithTitle
            title={intl.formatMessage({ id: 'user.manage.new.email.label' })}
          >
            <TextField
              fullWidth
              name="email"
              data-testid={TEST_ID.email}
              value={email}
              onChange={(e) => setEmail(e.target.value.trim())}
            />
            {!hasValidEmail && (
              <InlineAlert severity="error" sx={{ mt: 3 }}>
                {intl.formatMessage({ id: 'user.manage.new.email.invalid' })}
              </InlineAlert>
            )}
          </ContainerWithTitle>
        </Box>
      </UpdateUserFormWrapper>
    </Box>
  );
};
